.clients {
  padding-top: 30px;
  padding-bottom: 190px;
  position: relative;
}
@media (min-width: 768px) {
  .clients {
    padding-top: 105px;
    padding-bottom: 160px;
    padding-inline: 110px;
    margin-inline: auto;
    max-width: 1280px;
    box-sizing: border-box;
  }
}
.clients h2 {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .clients h2 {
    margin-bottom: 80px;
  }
}
.clients .dash {
  position: absolute;
  bottom: 0;
  right: 25px;
}
@media (min-width: 768px) {
  .clients .dash {
    right: 180px;
  }
}

.clientsLogos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.clientsLogos > div {
  flex: 1 1 40%;
}
@media (min-width: 768px) {
  .clientsLogos > div {
    flex: 1 1 auto;
  }
}
.clientsLogos > div > img {
  margin: 0 auto;
}