.portfolio {
  margin-top: 175px;
  margin-bottom: 43px;
}
@media (min-width: 768px) {
  .portfolio {
    padding-inline: 72px;
    margin-top: 215px;
    margin-inline: auto;
    max-width: var(--max-width);
    box-sizing: border-box;
  }
}
.portfolioHeader {
  padding-left: 24px;
  display: inline-block;
  position: relative;
}
@media (min-width: 768px) {
  .portfolioHeader {
    padding-left: 110px;
  }
}
.portfolioHeader h2 {
  margin: 0;
  line-height: normal;
}
.portfolioHeader .dash {
  position: absolute;
  bottom: 10px;
  right: -50px;
}
@media (min-width: 768px) {
  .portfolioHeader .dash {
    right: -80px;
    bottom: 14px;
  }
}
.portfolio .project-details {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  flex-direction: column;
}
@media (min-width: 768px) {
  .portfolio .project-details {
    flex-direction: row;
    align-items: end;
    padding: 0;
  }
}
.portfolio .project-details h2 {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .portfolio .project-details h2 {
    font-size: 36px;
    line-height: normal;
    margin: 0px 0 22px 25px;
  }
}
.portfolio .project-category {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 400;
  margin-top: 0;
}
@media (min-width: 768px) {
  .portfolio .project-category {
    gap: 8px;
    margin-right: 25px;
    margin-bottom: 27px;
    font-size: 20px;
  }
}
.portfolio-grid {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (min-width: 768px) {
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}
.portfolio-grid .item {
  align-self: stretch;
  z-index: 900;
  position: relative;
}
.portfolio-grid .item > div {
  position: relative;
  background-repeat: no-repeat;
  background-size: 180%;
  background-position: center;
  transition: background-size 0.5s ease;
}
@media (min-width: 768px) {
  .portfolio-grid .item > div {
    background-size: 120%;
  }
}
.portfolio-grid .item:hover .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--black);
  opacity: 0.4;
}
.portfolio-grid .item:hover > div {
  background-size: 200%;
}
@media (min-width: 768px) {
  .portfolio-grid .item:hover > div {
    background-size: 140%;
  }
}
.portfolio-grid .item:nth-child(6n+2) h2,
.portfolio-grid .item:nth-child(6n+2) .project-category, .portfolio-grid .item:nth-child(6n+2):hover h2, .portfolio-grid .item:nth-child(6n+2):hover .project-category, .portfolio-grid .item:nth-child(6n+3) h2,
.portfolio-grid .item:nth-child(6n+3) .project-category, .portfolio-grid .item:nth-child(6n+3):hover h2, .portfolio-grid .item:nth-child(6n+3):hover .project-category, .portfolio-grid .item:nth-child(6n+4) h2,
.portfolio-grid .item:nth-child(6n+4) .project-category, .portfolio-grid .item:nth-child(6n+4):hover h2, .portfolio-grid .item:nth-child(6n+4):hover .project-category, .portfolio-grid .item:nth-child(6n) h2,
.portfolio-grid .item:nth-child(6n) .project-category, .portfolio-grid .item:nth-child(6n):hover h2, .portfolio-grid .item:nth-child(6n):hover .project-category {
  color: var(--white);
}
.portfolio-grid .item:nth-child(6n+1):hover, .portfolio-grid .item:nth-child(6n+5):hover {
  color: var(--black);
}
.portfolio-grid .item:nth-child(6n+1), .portfolio-grid .item:nth-child(6n+1) > div {
  height: 560px;
}
@media (min-width: 768px) {
  .portfolio-grid .item:nth-child(6n+1), .portfolio-grid .item:nth-child(6n+1) > div {
    grid-row: span 2;
    grid-column: span 3;
    height: 800px;
    border-radius: 60px 0 0 0;
  }
  .portfolio-grid .item:nth-child(6n+1) .overlay, .portfolio-grid .item:nth-child(6n+1) > div .overlay {
    border-radius: 60px 0 0 0;
  }
}
.portfolio-grid .item:nth-child(6n+2), .portfolio-grid .item:nth-child(6n+2) > div {
  height: 300px;
}
@media (min-width: 768px) {
  .portfolio-grid .item:nth-child(6n+2), .portfolio-grid .item:nth-child(6n+2) > div {
    grid-column: span 2;
    height: 388px;
    border-radius: 60px 0 0 0;
  }
  .portfolio-grid .item:nth-child(6n+2) .overlay, .portfolio-grid .item:nth-child(6n+2) > div .overlay {
    border-radius: 60px 0 0 0;
  }
}
.portfolio-grid .item:nth-child(6n+3), .portfolio-grid .item:nth-child(6n+3) > div {
  height: 300px;
}
@media (min-width: 768px) {
  .portfolio-grid .item:nth-child(6n+3), .portfolio-grid .item:nth-child(6n+3) > div {
    grid-column: span 2;
    height: 388px;
    border-radius: 0 60px 0 0;
  }
  .portfolio-grid .item:nth-child(6n+3) .overlay, .portfolio-grid .item:nth-child(6n+3) > div .overlay {
    border-radius: 0 60px 0 0;
  }
}
.portfolio-grid .item:nth-child(6n+4), .portfolio-grid .item:nth-child(6n+4) > div {
  height: 300px;
}
@media (min-width: 768px) {
  .portfolio-grid .item:nth-child(6n+4), .portfolio-grid .item:nth-child(6n+4) > div {
    grid-column: span 2;
    height: 388px;
    border-radius: 0 60px 0 0;
  }
  .portfolio-grid .item:nth-child(6n+4) .overlay, .portfolio-grid .item:nth-child(6n+4) > div .overlay {
    border-radius: 0 60px 0 0;
  }
}
.portfolio-grid .item:nth-child(6n+5), .portfolio-grid .item:nth-child(6n+5) > div {
  height: 560px;
}
@media (min-width: 768px) {
  .portfolio-grid .item:nth-child(6n+5), .portfolio-grid .item:nth-child(6n+5) > div {
    grid-column: span 3;
    grid-row: span 2;
    height: 800px;
    border-radius: 0 60px 0 0;
  }
  .portfolio-grid .item:nth-child(6n+5) .overlay, .portfolio-grid .item:nth-child(6n+5) > div .overlay {
    border-radius: 0 60px 0 0;
  }
}
.portfolio-grid .item:nth-child(6n), .portfolio-grid .item:nth-child(6n) > div {
  height: 300px;
}
@media (min-width: 768px) {
  .portfolio-grid .item:nth-child(6n), .portfolio-grid .item:nth-child(6n) > div {
    grid-column: span 2;
    height: 388px;
    border-radius: 60px 0 0 0;
  }
  .portfolio-grid .item:nth-child(6n) .overlay, .portfolio-grid .item:nth-child(6n) > div .overlay {
    border-radius: 60px 0 0 0;
  }
}