.subheader_about {
  display: flex;
  flex-direction: row;
  margin-top: 200px;
  gap: 35px;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .subheader_about {
    margin-top: 270px;
    gap: 130px;
    flex-wrap: nowrap;
  }
}
.subheader_about > div {
  flex-basis: 100%;
}
@media (min-width: 768px) {
  .subheader_about > div.first {
    flex-basis: 515px;
  }
}
.subheader_about h2,
.subheader_about h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.subheader_about .dash {
  margin-top: 37px;
  float: right;
}
@media (min-width: 768px) {
  .subheader_about .dash {
    margin-top: 140px;
  }
}

.aboutTeam {
  margin-top: 60px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 55px;
}
@media (min-width: 768px) {
  .aboutTeam {
    margin-top: 175px;
    margin-bottom: 155px;
    padding-inline: 72px;
    flex-direction: row;
    gap: 110px;
    margin-inline: auto;
    max-width: var(--max-width);
    box-sizing: border-box;
  }
}
.aboutTeam .teamPic {
  position: relative;
  flex: 0 1 100%;
}
@media (min-width: 768px) {
  .aboutTeam .teamPic {
    flex: 1 1 auto;
  }
}
.aboutTeam .teamNames {
  flex: 0 1 40%;
  padding-inline: 24px;
}
@media (min-width: 768px) {
  .aboutTeam img {
    border-radius: 0 60px 0 0;
  }
}
.aboutTeam img + .dash {
  position: absolute;
  bottom: -10px;
  right: 24px;
}
@media (min-width: 768px) {
  .aboutTeam img + .dash {
    bottom: 0;
    right: -56px;
  }
}
.aboutTeam .names {
  margin-top: 50px;
  margin-bottom: 55px;
  list-style: none;
  padding-left: 0;
}
.aboutTeam .names h4 {
  margin-bottom: 2px;
  margin-top: 18px;
}
.aboutTeam .names .role {
  display: block;
  color: var(--secondary-text-color);
  font-size: 14px;
  line-height: 26px;
}
.aboutTeam .names.workWithNames {
  margin-top: 25px;
}

.process {
  overflow: scroll;
}
@media (min-width: 768px) {
  .process {
    padding-left: 182px;
    padding-right: 72px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.process_container {
  max-width: 1400px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .process_container {
    overflow: hidden;
  }
}
.process .steps {
  display: flex;
  flex-wrap: nowrap;
}
.process .steps .item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  width: 230px;
  min-width: 230px;
  padding-right: 63px;
}
@media (min-width: 768px) {
  .process .steps .item {
    width: 530px;
    min-width: 530px;
    padding-right: 135px;
  }
}
.process .steps .item h1 {
  font-size: 110px;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 70px;
}
@media (min-width: 768px) {
  .process .steps .item h1 {
    font-size: 190px;
  }
}
.process .steps .item h3 {
  margin-top: -5px;
  margin-bottom: 0;
  margin-left: 0;
}
.process .steps .item p {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .process .steps .item p {
    margin-top: 10px;
  }
}
.process .steps .item .itemHeader {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
@media (min-width: 768px) {
  .process .steps .item .itemHeader {
    flex-wrap: nowrap;
    align-items: baseline;
    gap: 60px;
  }
}
.process .steps .item .itemHeaderNumber {
  display: flex;
  align-items: baseline;
}
@media (min-width: 768px) {
  .process .steps .item .itemHeaderNumber {
    gap: 35px;
  }
}
.process .steps .item.active h1,
.process .steps .item.active h3,
.process .steps .item.active p {
  color: var(--white);
}
.process .steps .item.active .dash {
  background-color: var(--white);
}
.process .steps h1,
.process .steps h3,
.process .steps p {
  color: var(--secondary-text-color);
}
.process .steps .dash {
  background-color: var(--secondary-text-color);
}

.boast {
  margin-bottom: 15px;
  padding-right: 0;
}
@media (min-width: 768px) {
  .boast {
    margin-bottom: 0;
  }
}
.boastContainer {
  display: flex;
  margin-top: 115px;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  padding-left: 0;
  gap: 40px;
}
@media (min-width: 768px) {
  .boastContainer {
    gap: 130px;
  }
}
.boastContainer .left {
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .boastContainer .left {
    flex: 1 1 10%;
  }
}
.boastContainer .left ul {
  color: var(--white);
  padding-left: 25px;
}
@media (min-width: 768px) {
  .boastContainer .left ul {
    margin-top: 100px;
  }
}
.boastContainer .left ul p {
  margin-left: 5px;
}
.boastContainer .left ul li + li {
  margin-top: 25px;
}
.boastContainer .right {
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .boastContainer .right {
    margin-top: 210px;
  }
}
.boastContainer .right .firstPic {
  padding-right: 60px;
  margin-bottom: -4px;
}
.boastContainer .right .firstPicContainer {
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  .boastContainer .right .firstPic {
    padding-right: 0;
    margin-bottom: -1px;
  }
  .boastContainer .right .firstPic img {
    border-radius: 60px 0 0 0;
  }
}
.boastContainer .right .firstPic .dash {
  position: absolute;
  top: -10px;
  right: -36px;
}
@media (min-width: 768px) {
  .boastContainer .right .firstPic .dash {
    top: -14px;
    right: -56px;
  }
}
.boastContainer .right .secondPic {
  padding-left: 50%;
}
@media (min-width: 768px) {
  .boastContainer .right .secondPic {
    padding-left: 40%;
    position: relative;
    top: -4px;
  }
  .boastContainer .right .secondPic img {
    border-radius: 0 0 0 60px;
  }
}