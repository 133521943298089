.nav-panel,
.nav-panel__hidden {
  max-width: 598px;
  width: 100%;
  background: var(--black);
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 1000;
}

.nav-panel__hidden {
  max-width: 0;
}

.nav-content {
  display: flex;
  flex-direction: column;
  color: var(--lightgray);
  padding-top: 33px;
  padding-right: 25px;
  padding-left: 25px;
}
@media (min-width: 768px) {
  .nav-content {
    padding-top: 80px;
    padding-right: 75px;
    padding-left: 105px;
  }
}
.nav-content a {
  text-decoration: none;
  color: var(--white);
  position: relative;
  display: inline-block;
}
.nav-content a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.nav-content a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.nav-content ul.nav-menu {
  margin-top: 65px;
  margin-bottom: 45px;
  list-style: none;
  padding-left: 0;
}
@media (min-width: 768px) {
  .nav-content ul.nav-menu {
    margin-top: 90px;
  }
}
.nav-content ul.nav-menu a {
  display: inline-block;
  font-family: "PPNeueMachina";
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
}
@media (min-width: 768px) {
  .nav-content ul.nav-menu a {
    font-size: 44px;
    line-height: 56px;
  }
}
.nav-content ul.nav-menu li + li {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .nav-content ul.nav-menu li + li {
    margin-top: 28px;
  }
}

.nav-contact {
  list-style: none;
  padding-left: 0;
}
.nav-contact li a {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 20px;
  line-height: 50px;
}
@media (min-width: 768px) {
  .nav-contact li a {
    font-size: 24px;
    line-height: 52px;
  }
}
.nav-contact li + li {
  margin-top: 5px;
}

.nav-close {
  display: block;
  max-width: 100%;
  width: 30px;
  height: 30px;
  align-self: flex-end;
  cursor: pointer;
}
@media (min-width: 768px) {
  .nav-close {
    width: 50px;
    height: 50px;
  }
}
.nav-close:hover {
  animation: closePanel 0.5s linear;
}

@keyframes closePanel {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 180deg;
  }
}
.dark-mode.nav-panel {
  background: var(--lightgray);
}
.dark-mode .nav-content {
  color: var(--primary-text-color);
}
.dark-mode .nav-content a {
  color: var(--primary-text-color);
}
.dark-mode .nav-content a::after {
  background-color: var(--black);
}