footer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap-reverse;
  gap: 40px;
}
@media (min-width: 768px) {
  footer {
    gap: 68px;
  }
}

.footer {
  padding-top: 50px;
}
@media (min-width: 768px) {
  .footer {
    padding-top: 130px;
  }
}
.footer h2 {
  margin-bottom: 75px;
}
@media (min-width: 768px) {
  .footer h2 {
    margin-bottom: 122px;
  }
}
.footer-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .footer-logo {
    width: auto;
  }
}
@media (min-width: 768px) {
  .footer-contact {
    flex-grow: 1;
  }
}
.footer-contact p {
  font-weight: 300;
  margin-top: 0;
  font-size: 20px;
  line-height: 0.8;
}
@media (min-width: 768px) {
  .footer-contact p {
    font-size: 24px;
  }
}
.footer-contact ul {
  list-style: none;
  padding: 0;
  font-weight: 300;
  font-size: 20px;
  line-height: 50px;
}
@media (min-width: 768px) {
  .footer-contact ul {
    -moz-columns: 2;
         columns: 2;
    font-size: 24px;
    line-height: 54px;
  }
  .footer-contact ul > li.margin {
    margin-left: 57px;
  }
}
.footer-contact a {
  display: inline-block;
}
.footer-contact a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-text-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.footer-contact a:hover {
  color: var(--primary-text-color);
}
.footer-contact a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.logo-footer {
  display: block;
  width: 195px;
  height: auto;
  max-width: 168px;
}
@media (min-width: 768px) {
  .logo-footer {
    max-width: 100%;
  }
}

.copyright {
  margin-block: 60px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .copyright {
    margin-top: 80px;
    justify-content: normal;
  }
}
.copyright p {
  margin: 0 0 -5px;
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .copyright p {
    font-size: 18px;
    line-height: 26px;
  }
}
.copyright .panipresta-logo {
  max-width: 108px;
}
@media (min-width: 768px) {
  .copyright .panipresta-logo {
    max-width: none;
  }
}

.dark-mode .copyright,
.dark-mode .footer-contact a {
  color: var(--lightgray);
}
.dark-mode .copyright::after,
.dark-mode .footer-contact a::after {
  background-color: var(--lightgray);
}