.portfolio-main {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 890px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .portfolio-main {
    height: 650px;
    flex-direction: row;
  }
}
.portfolio-main-box {
  margin-top: 105px;
  padding-right: 0;
}
@media (min-width: 768px) {
  .portfolio-main-box {
    margin-inline: auto;
    max-width: var(--max-width);
  }
}
.portfolio-main > .item {
  position: absolute;
  top: 125px;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
@media (min-width: 768px) {
  .portfolio-main > .item {
    top: 0;
    flex-wrap: nowrap;
    gap: 165px;
    height: 100%;
  }
}
.portfolio-main > .item .desc .portfolio-category,
.portfolio-main > .item .desc .portfolio-title,
.portfolio-main > .item .desc .portfolio-desc {
  transition: var(--transition);
  opacity: 0;
}
.portfolio-main > .item.activeSlide .desc .portfolio-category,
.portfolio-main > .item.activeSlide .desc .portfolio-title,
.portfolio-main > .item.activeSlide .desc .portfolio-desc {
  opacity: 1;
  transform: translateX(0);
}
.portfolio-main > .item.lastSlide .desc .portfolio-category,
.portfolio-main > .item.lastSlide .desc .portfolio-title,
.portfolio-main > .item.lastSlide .desc .portfolio-desc {
  transform: translateX(-200%);
}
.portfolio-main > .item.nextSlide .desc .portfolio-category,
.portfolio-main > .item.nextSlide .desc .portfolio-title,
.portfolio-main > .item.nextSlide .desc .portfolio-desc {
  transform: translateX(200%);
}
.portfolio-main > .item.nextSlide {
  z-index: 30;
}
.portfolio-main > .item.lastSlide {
  z-index: 40;
}
.portfolio-main > .item.activeSlide {
  z-index: 50;
}
.portfolio-main > .item.activeSlide .imgSlide {
  position: absolute;
  transform: translateX(0);
  animation: nextSideAnimation 1s ease;
}
@keyframes nextSideAnimation {
  0% {
    top: 0;
    left: 744px;
  }
  100% {
    top: 0;
    left: 0;
  }
}
.portfolio-main > .item .secondaryBtn {
  display: none;
}
.portfolio-main > .item.activeSlide .secondaryBtn {
  display: block;
}
@media (min-width: 768px) {
  .portfolio-main {
    flex-wrap: nowrap;
    gap: 165px;
  }
  .portfolio-main .container {
    padding-right: 0;
    margin: 0;
  }
  .portfolio-main h3 {
    margin-top: 103px;
  }
}
.portfolio-main h3.mobileDisplay {
  margin-bottom: 70px;
}
.portfolio-main .desc {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.portfolio-main .desc .secondaryBtn {
  width: 244px;
  box-sizing: border-box;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .portfolio-main .desc .secondaryBtn {
    margin: 0;
  }
}
.portfolio-main .desc .portfolio-category {
  font-size: 14px;
  color: var(--gray);
  line-height: 26px;
  display: block;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .portfolio-main .desc .portfolio-category {
    font-size: 18px;
  }
}
.portfolio-main .desc .portfolio-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .portfolio-main .desc .portfolio-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.portfolio-main .desc .portfolio-desc {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .portfolio-main .desc .portfolio-desc {
    font-size: 20px;
    line-height: 32px;
  }
}
.portfolio-main .desc .portfolio-link {
  display: inline-block;
  margin-bottom: 65px;
  position: relative;
  width: 105px;
}
@media (min-width: 768px) {
  .portfolio-main .desc .portfolio-link {
    margin-bottom: 138px;
  }
}
.portfolio-main .desc .portfolio-link::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-text-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.portfolio-main .desc .portfolio-link:hover {
  color: var(--primary-text-color);
}
.portfolio-main .desc .portfolio-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.portfolio-main .desc .portfolio-link img {
  position: relative;
  margin-left: 12px;
  top: 7px;
}
.portfolio-main .desc button {
  justify-content: center;
  margin-inline: 40px;
}
@media (min-width: 768px) {
  .portfolio-main .desc button {
    margin-inline: 0;
  }
}
.portfolio-main article {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-shrink: 8;
}
@media (min-width: 768px) {
  .portfolio-main article {
    flex-basis: auto;
    overflow: hidden;
  }
}
.portfolio-main figure {
  flex-shrink: 1;
  margin: 0;
  overflow: hidden;
  height: 350px;
  width: 100%;
  position: relative;
}
.portfolio-main figure .slide {
  position: absolute;
  overflow: hidden;
  inset: 0;
}
.portfolio-main figure .imgSlide {
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  .portfolio-main figure .imgSlide {
    border-radius: 60px 0 0 0;
  }
}
@media (min-width: 768px) {
  .portfolio-main figure {
    margin-right: 72px;
    width: 787px;
    height: 650px;
    border-radius: 60px 0 0 0;
  }
  .portfolio-main figure .slide {
    border-radius: 60px 0 0 0;
  }
  .portfolio-main figure .imgSlider {
    border-radius: 60px 0 0 0;
    height: 100%;
  }
}