:root {
  --calc: ((100vw - 360px) / (1400 - 360));
  --black: #2b2e3f;
  --violet: #606688;
  --lightgray: #f6f6f5;
  --bggray: #e5e5e5;
  --gray: #878a97;
  --white: #fff;
  --primary-text-color: var(--black);
  --secondary-text-color: var(--gray);
  --transition: all 0.8s linear;
  --max-width: 1440px;
}

body {
  font-family: "Roboto";
  font-weight: 400;
  color: var(--primary-text-color);
  margin: 0;
  background: var(--bggray);
}

h1,
h2,
h3,
.h1, .h2, .h3 {
  font-family: "PPNeueMachina";
  font-weight: 400;
  color: var(--primary-text-color);
}

h4, .h4 {
  font-family: "Roboto";
  font-weight: 700;
  color: var(--primary-text-color);
}

h1, .h1 {
  font-size: 54px;
  line-height: 70px;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 130px;
    line-height: 140px;
  }
}

h2, .h2 {
  font-size: 32px;
  line-height: 42px;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 60px;
    line-height: 80px;
  }
}

h3, .h3 {
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 44px;
    line-height: 56px;
  }
}

h4, .h4 {
  font-size: 16px;
  line-height: 26px;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 22px;
    line-height: 32px;
  }
}

p, ul {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 768px) {
  p, ul {
    font-size: 20px;
    line-height: 32px;
  }
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 768px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 182px;
    padding-right: 182px;
    box-sizing: border-box;
  }
}

.secondaryBtn {
  border: 1px solid var(--black);
  color: var(--primary-text-color);
  border-radius: 30px;
  padding: 13px 40px;
  font-size: 20px;
  line-height: 32px;
  background: transparent;
  position: relative;
  z-index: 999;
  text-align: center;
}
.secondaryBtn:hover {
  cursor: pointer;
  background: var(--black);
  color: var(--white);
}
.secondaryBtn:active, .secondaryBtn:visited {
  color: var(--primary-text-color);
}

a {
  text-decoration: none;
  color: var(--primary-text-color);
  position: relative;
  z-index: 999;
}
a:hover, a:visited:hover {
  text-decoration: none;
  color: var(--white);
}
a:visited, a:focus, a:active {
  color: var(--primary-text-color);
}

.dash {
  display: inline-block;
  width: 36px;
  height: 10px;
  background: var(--black);
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@media (min-width: 768px) {
  .dash {
    width: 56px;
    height: 14px;
  }
}

.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode p {
  color: var(--lightgray);
}
.dark-mode .dash {
  background: var(--lightgray);
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@media (min-width: 768px) {
  .mobileHidden {
    display: block;
  }
  .mobileDisplay {
    display: none;
  }
}
@media (max-width: 768px) {
  .mobileHidden {
    display: none;
  }
  .mobileDisplay {
    display: block;
  }
}