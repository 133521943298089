.project {
  margin: 200px auto 45px;
  max-width: var(--max-width);
}
.project h1 {
  margin-bottom: 21px;
}
.project img {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .project img.img1, .project img.img6 {
    border-radius: 0 0 60px 0;
  }
  .project img.img2, .project img.img3 {
    border-radius: 60px 0 0 0;
  }
}
.project-desc {
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 45px 0 55px;
  gap: 46px;
}
@media (min-width: 768px) {
  .project-desc {
    flex-wrap: wrap;
    gap: 125px;
    margin-bottom: 130px;
  }
  .project-desc .desc {
    flex-basis: 70%;
  }
}
.project-category {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 31px;
}
.project-result {
  margin-bottom: 110px;
}
.project-result .result h2 {
  margin-bottom: 40px;
}
.project-result .elements ul {
  padding-left: 25px;
  margin-top: 25px;
  margin-bottom: 0;
}
.project-challenges, .project-result {
  margin: 42px 0 54px;
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
}
.project-challenges .result > p, .project-result .result > p {
  margin: 0;
}
@media (min-width: 768px) {
  .project-challenges, .project-result {
    flex-wrap: wrap;
    gap: 125px;
    margin-bottom: 160px;
    margin-top: 110px;
    gap: 35px;
  }
  .project-challenges .challenges,
  .project-challenges .result, .project-result .challenges,
  .project-result .result {
    flex-basis: 70%;
  }
  .project-challenges .team > p,
  .project-challenges .elements > p, .project-result .team > p,
  .project-result .elements > p {
    margin-top: 0;
    line-height: normal;
  }
}
.project-challenges ul > li + li, .project-result ul > li + li {
  margin-top: 7px;
}
.project-challenges .challenges ul, .project-result .challenges ul {
  margin: 0;
  padding-left: 25px;
}
.project-challenges .team ul, .project-result .team ul {
  list-style: none;
  margin-top: 25px;
  padding-left: 0;
}
.project-subheader h3 {
  margin-top: 120px;
  font-size: 32px;
  line-height: 42px;
}
@media (min-width: 768px) {
  .project-subheader h3 {
    margin-top: 150px;
    font-size: 44px;
    line-height: 56px;
  }
}