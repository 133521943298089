.about {
  position: relative;
  margin: 75px 0 0;
}
@media (min-width: 768px) {
  .about {
    margin: 130px 0 0;
    padding-inline: 72px;
    margin-inline: auto;
    max-width: var(--max-width);
    box-sizing: border-box;
  }
}
.about img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 60px 0 0 0;
}
.about figcaption.figcaption {
  bottom: 0;
  padding-bottom: 30px;
  padding-top: 45px;
  padding-inline: 24px;
  position: relative;
  top: 0;
  background: var(--white);
}
@media (min-width: 768px) {
  .about figcaption.figcaption {
    position: absolute;
    background-color: rgba(245, 245, 245, 0.8);
    top: auto;
    bottom: 0;
    padding-inline: 160px;
    padding-top: 90px;
    padding-bottom: 0;
  }
}
.about figcaption .dash {
  position: absolute;
  top: 0;
}
@media (min-width: 768px) {
  .about figcaption .dash {
    top: -14px;
  }
}
.about .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .about .content {
    flex-direction: row;
  }
}
.about .content_item {
  flex: 1 0 auto;
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .about .content_item {
    flex: 1;
  }
}
.about .content_item--btn {
  flex: 1 0 auto;
  text-align: right;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .about .content_item--btn {
    flex: 1;
  }
}
.about h2 {
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  .about h2 {
    font-size: 44px;
    line-height: 56px;
  }
}
.about-img {
  position: relative;
}
.about-overlay {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
}