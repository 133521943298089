header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 34px;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 995;
  padding-inline: 24px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  header {
    max-width: 1440px;
    padding-inline: 72px;
    margin-block: 60px;
  }
}

header .logo {
  display: block;
  max-width: 100%;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  cursor: pointer;
}
@media (min-width: 768px) {
  header .logo {
    width: 86px;
    height: 86px;
  }
}

header .nav-bar {
  display: block;
  max-width: 100%;
  width: 36px;
  height: 27px;
  cursor: pointer;
}
@media (min-width: 768px) {
  header .nav-bar {
    width: 56px;
    height: 42px;
  }
}